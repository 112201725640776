import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import moment from 'moment'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Image from "../components/image"
import Helmet from "react-helmet"
import Review from "../components/review"
import Share from "../components/share"
import TweetWidget from "../components/tweetWidget"
import Campaign from "../components/campaign"
import Tag from "../components/tag"
import { XIcon} from "react-share"
import {OldMessageLong, ImageFilename} from '../utils/common'

const typeSizeBrandSubbrandPage = ({ data, location, pageContext: { type_id, size_id, brand_id, subbrand_id, search_title } }) => {
	const type = data.typesYaml
	const size = data.sizesYaml
	const brand = data.brandsYaml
	const subbrand = data.subbrandsYaml
	const sizeStr = ((type.cid !== 'wipe') && (type.cid !== 'handwipe'))? 'サイズ' : 'タイプ' 
	const itemStr = ((type.cid !== 'wipe') && (type.cid !== 'handwipe'))? 'オムツ' : type.description
	const subbrandDetail = subbrand.types.find(n => {
		return n.type_id === type.cid
	}).sizes.find(n => {
		return n.size_id === size.cid
	})
	const min = subbrandDetail.min !== 0 ? `${subbrandDetail.min}${subbrandDetail.unit}` : ``
	const max = `${subbrandDetail.max}${subbrandDetail.unit}`
	let min_max = min + '～' + max
	if((subbrandDetail.min === 0) && (subbrandDetail.max === 0))
	{
		min_max = '-'
	}
	// 更新日付をGraphQLから直接表示するとUTCとなるため、自前のmoment.jsで表示
	const update = moment(data.contentfulItems.updatedAt).format('YYYY年M月D日')
	let cnt = 0
	let description = ``
	let preTitle = ``	
	if(data.contentfulItems.itemList.length){
		description = `${brand.description} ${subbrand.description} ${type.description} ${size.description}${sizeStr} 1枚あたりの最安値は${data.contentfulItems.itemList[0].per.toFixed(1)}円。${data.contentfulItems.itemList[0].shop}で販売しています。`
		preTitle = `${data.contentfulItems.itemList[0].per.toFixed(1)}円/枚`	
	}else{
		description = `${brand.description} ${subbrand.description} ${type.description} ${size.description}${sizeStr} は販売している店舗がありません。`
		preTitle = ``	
	}
	
	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": type.description,
			"item": "https://hula-la.com/"+type.cid+".html"
		},{
			"@type": "ListItem",
			"position": 3,
			"name": size.description,
			"item": "https://hula-la.com/"+type.cid+"_"+size.cid+".html"
		},{
			"@type": "ListItem",
			"position": 4,
			"name": brand.description,
			"item": "https://hula-la.com/"+brand.cid+"_"+type.cid+"_"+size.cid+".html"
		}]
	};

	// 商品の構造化データ
	const jsonLdProduct = {
		"@context": "https://schema.org",
		"@type": "Product",
		"name": brand.description+subbrand.description+" "+type.description+"タイプ "+size.description+sizeStr,
		"size": size.description,
		"description": brand.maker+"から発売されている"+brand.description+subbrand.description+" "+type.description+"タイプ "+size.description+sizeStr,
		"gtin13": data.contentfulItems.jan,
		"brand": {
			"@type": "Brand",
			"name": brand.maker
		},
		"offers": {
			"@type": "AggregateOffer",
			"offerCount": data.contentfulItems.itemList.length,
			"lowPrice": data.contentfulItems.itemList.length > 0 ? Math.min(...data.contentfulItems.itemList.map((p) => p.price)) : 0,
			"highPrice": data.contentfulItems.itemList.length > 0 ? Math.max(...data.contentfulItems.itemList.map((p) => p.price)) : 0,
			"priceCurrency": "JPY"
		}
	};

	return (
  <Layout>
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li><Link to={`/${type.cid}.html`}>{type.description}</Link></li>
				<li><Link to={`/${type.cid}_${size.cid}.html`}>{size.description}</Link></li>
				<li><Link to={`/${brand.cid}_${type.cid}_${size.cid}.html`}>{brand.description}</Link></li>
			</ul>
		</nav>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				 <Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLdProduct)}</script>
   			</Helmet>
				<h2 id={`${subbrand.subbrand_id}`} className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{brand.description} {subbrand.description} {type.description} {size.description}{sizeStr}</h2>
				{/* <MostCheap item={data.contentfulItems} update={update}/> */}
        <TweetWidget urlPath={`https://hula-la.com${location.pathname}`} />				
				<h4 className="title is-6" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>ランキング<small> ({update}更新)</small></h4>
				<p style={{fontSize:`0.8rem`, color: `#808080`}}><small>&nbsp;&nbsp;&nbsp;<strong>割引後にポイントと送料を含めた実質価格で</strong>で1枚あたりを計算！</small></p>
				<p style={{fontSize:`0.8rem`, color: `#808080`, marginBottom: `0.5rem`}}><small>&nbsp;&nbsp;&nbsp;（本ページのリンクには広告が含まれています）</small></p>
        <div class="container"style={{marginRight: `0.0rem`}}>
          <div class="columns is-mobile">
            <div class="column is-9" style={{paddingBottom: `0.9rem`}}>
              <div className="has-text-centered" style={{paddingTop: `0.3rem`, paddingBottom: `0.0rem`}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {/* <a href="https://x.com/hula_la_" target='_blank'><XIcon size={18} round /></a> */}
                {/* <p style={{fontSize:`0.7rem`, marginTop: `-0.3rem`}}><a href="https://x.com/hula_la_" target='_blank' style={{color: `#0783c2`}}>&nbsp;&nbsp;&nbsp;\\ X(Twitter)で更新ポスト //</a></p> */}
									<p style={{fontSize:`0.7rem`, marginTop: `0.0rem`}}><a href={`https://hula-la.com/campaign.html`} target='_blank' style={{color: `#0783c2`}}>キャンペーン情報</a></p>
               </div>
              </div>              
            </div>
            <div class="column is-3" style={{paddingBottom: `0.9rem`}}>
            </div>
          </div>
        </div>
				<div className="columns is-multiline is-gapless">
					{data.contentfulItems.itemList.map(item => {
						const sendPrice = item.sendPrice > 0 ? `送料${item.sendPrice}円` : item.sendPrice === 0 ? `送料無料` : item.sendPrice === -1 ? `実店舗` : '店舗受取';
						cnt += 1
						const order = cnt
						let price_org = ``
						if(item.txt){
							if(item.txt.match(/OFF/))
							{
								price_org = `${PriceKanma(item.price_org)}円`
							}
						}
						const filename = ImageFilename(brand.cid, type.cid, size.cid, subbrand.subbrand_id, item)
						const oldMessageLong = OldMessageLong(item) 
						return (
							<div className="column is-one-third">
								<a href={`${item.href}`} rel="nofollow"
									onClick={e => {
										trackCustomEvent({
											category: `${type.cid}_${size.cid}_${brand.cid}_${subbrand.subbrand_id}`,
											action: `${item.shop}`,
											label: `${order}位`,
											transport: 'beacon'
										})
									}}									 
								>
									<div className="card">
										<div className="card-content" style={{padding: `0.8rem`}}>
											<div className="media">
												<div className="media-left">
												{ cnt !== 0 && (
													<span><strong>&nbsp;&nbsp;&nbsp;&nbsp;{order}<span style={{fontSize:`0.9rem`}}>位</span></strong></span>
												)}
	                      <Image filename={filename} alt={`${brand.description}${subbrand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
												{ cnt === 1 && (
													<span class="has-text-danger" style={{fontSize:`0.8rem`}}>&nbsp;&nbsp;&nbsp;<strong>最安値</strong></span>
												)}
												</div>
												<div className="media-content">
													<div className="content">
														<p style={{marginBottom: `0.2rem`}}>
															<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item.per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/枚</span></span>
															<span style={{color: `#ff0000`}} className="is-size-7">&nbsp;&nbsp;{PriceKanma(item.price)}円<span style={{color: `#808080`}}>&nbsp;<strike>{price_org}</strike></span></span>&nbsp;
															<Tag item={item} />
															<br />
															{ 'point' in item && item.point > 0 && (
																<><span className="tag is-danger is-light" style={{fontSize:`0.6rem`}}><b>{item.point}ポイント</b></span>&nbsp;</>
															)}
															<span className="tag" style={{fontSize:`0.6rem`}}>{sendPrice}</span>&nbsp;
															<span className="tag" style={{fontSize:`0.6rem`}}>{item.num}枚入</span>&nbsp;
															{ oldMessageLong !== '' && (
																<><span className="tag" style={{fontSize:`0.6rem`}}>{oldMessageLong}</span>&nbsp;</>
															)}
															<br />
															<span style={{color: `#0783c2`}} className="is-size-7">&nbsp;&nbsp;{item.shop}</span>
															<Review item={item} category={`${type.cid}_${size.cid}_${brand.cid}_${subbrand.subbrand_id}`}/>
														</p>
														<Campaign item={item} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</a>
							</div>
							)
						})}
				</div>
				<Share articleUrl={`https://hula-la.com${location.pathname}`} articleTitle={`【${preTitle}】${brand.description} ${subbrand.description} ${type.description} ${size.description}${sizeStr}`} />
				<div className="content is-small">
					<h4 className="title is-6" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>詳細</h4>
						<div className="columns">
							<div className="column is-4">
							</div>
							<div className="column is-7 is-offset-1">
								<table className="table">
									<tbody>
										<tr>
											<td className="has-text-right">
												<strong>メーカー</strong>
											</td>
											<td>{brand.maker}</td>
										</tr>
										<tr>
											<td className="has-text-right">
												<strong>製品名</strong>
											</td>
											<td>{brand.description}{subbrand.description}</td>
										</tr>
										<tr>
										<td className="has-text-right">
											<strong>タイプ</strong>
										</td>
										<td>{type.description}</td>
									</tr>
									<tr>
										<td className="has-text-right">
											<strong>大きさ</strong>
										</td>
										<td>{size.description}{sizeStr}（{min_max}）</td>
									</tr>
									{
									<tr>
										<td className="has-text-right">
											<strong>1パックの枚数</strong>
										</td>
										<td>{subbrandDetail.num}</td>
									</tr>
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<br></br>
			</>
		<Seo title={`${brand.description} ${subbrand.description} ${type.description} ${size.description}`} description={description} canonical={location.pathname} />
  </Layout>
	)
}

export const query = graphql`
	query($brand_id: String! $type_id: String! $size_id: String! $subbrand_id: String!) {
		brandsYaml(cid: { eq: $brand_id}) {
			cid
			description
			maker
		}
		sizesYaml(cid: { eq: $size_id}) {
			cid
			description
		}
		typesYaml(cid: { eq: $type_id}) {
			cid
			description
		}
		subbrandsYaml(brand_id: { eq: $brand_id} subbrand_id: { eq: $subbrand_id}) {
			brand_id
			subbrand_id
			description
			types {
				type_id 
				sizes {
					size_id
					min
					max
					unit
					num	
				}
			}
		}
		contentfulItems(brandId: { eq: $brand_id} typeId: { eq: $type_id} sizeId: { eq: $size_id} subbrandId: { eq: $subbrand_id}) {
			jan
			brandId
			subbrandId
			typeId
			sizeId
			itemList {
				num
				shopId
				shop
				href
				per
				per_org
				price
				price_org
				sendPrice
				souryou
				txt
				point
				star
				review
				reviewUrl
			}
			updatedAt
		}
	}
`

export default typeSizeBrandSubbrandPage

function PriceKanma(_price){
	var price = _price.toString(10);
	var length = price.length;
	var price_kanma;
	if(length > 3)
	{
		price_kanma = price.slice(0,length-3) + "," + price.slice(length-3);
	}
	else
	{
		price_kanma = price;
	}
	return price_kanma;
}